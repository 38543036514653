<template>
    <div class="list_container appliedList">
        <div class="search-box">
            <i-header :title="config['title'] ? config['title'] : ''"
                      :showBack="true" @back-event="toBack">
            </i-header>
            <i-search v-model="searchKey"
                      shape="round"
                      clearable
                      clear-trigger="always"
                      @input="onSearch"
                      :placeholder="`请输入会议名称/${tenantCode === 'dsm' ? '申请书' : '会议'}编号${tenantCode === 'crossevent' ? '/预算编号': ''}`"></i-search>
            <Tabs v-model="activeName" @change="tabChange">
                <Tab title="待授权" name="1"></Tab>
                <Tab title="已同意" name="0"></Tab>
                <Tab title="已拒绝" name="2"></Tab>
            </Tabs>
        </div>
        <event-item-list ref="eventlist"></event-item-list>
    </div>
</template>

<script>
/**
 * @description 会议列表公共组件，没有filter，联系客服，只有搜索和展示，以及跳转详情,所有参数都来自url的传递
 */
import EventItemList from "../../views/EventList/EventItemList";
import {debounce} from 'lodash'
import { Tab, Tabs } from 'vant';

export default {
    name: "index",
    components: {
        EventItemList,
        Tab,Tabs
    },
    data() {
        return {
            config: {}, // 页面相关配置，通过url参数传递
            tenantCode: this.$smartStorage.get("tenant") || this.$cookie.get("tenant"),
            searchKey: this.$smartStorage.get('lastListParams')?.searchKey || "",
            activeName: '1',
            tabs: [
                {name: '1', title: '待授权'}
            ]
        }
    },
    methods: {
        // tab
        tabChange(status) {
            console.log(this.activeName);
            this.onSearch()
        },
        toBack() {
            this.$router.back();
        },
        onSearch: debounce(function () {
            this.$root.$eventHub.$emit("updateEventList", {
                searchKey: this.searchKey,
                filterData: {
                    Status: this.activeName,
                    Flag: 2
                }
            })
        }, 800)
    },
    created() {
        const {config, queryListExpr} = this.$route.query;
        this.config = JSON.parse(config);
        this.$smartStorage.set("homeQueryListExpr", {listExpr: queryListExpr});
    },
    mounted() {
        this.$refs.eventlist.iheight = "calc(100vh - 0.9rem)";
    },
    beforeCreate() {
        this.$nextTick(() => {
            // 在子组件的生命周期created之前注入初始值
            this.$smartStorage.set('lastListParams', {
                filterData: {
                    Status: this.activeName,
                    Flag: 2
                },
            })
        })
    },
    beforeDestroy() {
        this.$smartStorage.remove('lastListParams')
    }
}
</script>

<style lang="less" scoped>
.appliedList {
    .van-tabs__line {
        background-color: var(--themeColor);
    }

    .search-box {
        //display: flex;
        //align-items: center;
        width: 100vw;
        //padding: 0.2rem 0.18rem;
        box-sizing: border-box;

        svg {
            //margin-left: 0.1rem;
        }

        .back-icon {
            height: 0.3rem;
            display: flex;
            align-items: center;
            margin-right: 0.1rem;
            flex-shrink: 0
        }

        /deep/ .van-search {
            //width: 100%;
            margin: 0 0.18rem;
            padding: unset;
            background: #F5F5F5;
            border-radius: 0.04rem;

            .van-search__content {
                height: 0.3rem;
                background: #F5F5F5;
                //box-shadow: 0px 0.02rem 0.13rem 0px rgba(35, 24, 21, 0.1);
                display: flex;
                align-items: center;
                font-size: 0.14rem;

                .van-cell {
                    height: 0.3rem;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                }

                .van-field__control {
                    height: 0.3rem;
                }

                .van-field__control::placeholder {
                    color: #909399;
                    -webkit-text-fill-color: #909399 !important;
                }
            }
        }

        /deep/ .van-icon-search {
            color: #c0c4cc;
        }

        /deep/ .van-tabs {
            .van-tabs__line {
                background-color: var(--themeColor);
            }
        }
    }

}

</style>
